<template>
	<el-dialog :visible.sync="showModel" :close-on-click-modal="false">
		<div id="mapAdd" style="width: 100%; height: 500px"></div>
	</el-dialog>
</template>

<script>
	import { forEach } from "jszip";
	// import {getTrackWorkPage} from "@/RequestPort/checkingIn";
	export default {
		name: "workLocus",
		data() {
			return {
				showModel: false,
				wms: null,
			};
		},
		mounted() {
			// (type='line' and id in (1)) or (type='point' and id in (1))
		},
		methods: {
			open(val) {
         console.log(val)
				let that = this;
				this.showModel = true;
				this.$nextTick(() => {
         
					that.map = new AMap.Map("mapAdd", {
						resizeEnable: true,
						center: val,
						zoom: 14,
					});
					let markerqi = new AMap.Marker({
						position:val,
						offset: new AMap.Pixel(-10, -30),
						zoom: 13,
					});
					that.map.add(markerqi);
				});
			},
		},
	};
</script>